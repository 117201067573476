import axios from 'axios'
import { useState } from 'react'
import domain from '../constants/domain'
import Link from 'next/link'
import Image from 'next/image'

const Footer = () => {
  const [submit, setSubmit] = useState(false)
  const submitHandler = (e) => {
    e.preventDefault()
    const name = e.target[0].value
    const email = e.target[1].value
    const message = e.target[2].value
    axios
      .post(domain + 'contact-us', { name, email, message })
      .then((response) => {
        e.target[0].value = ''
        e.target[1].value = ''
        e.target[2].value = ''
        setSubmit(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <footer className="w-full py-16 bg-themeDarkBlue text-white">
        <div className="container m-auto px-4">
          <div className="flex flex-row items-start justify-items-start">
            <div className="grid md:grid-cols-3 grid-cols-1 gap-10">
              <div className="px-7">
                <h4 className="text-2xl">About Us</h4>
                <p className="pt-4">
                  KahanChale is an AI platform that takes the interests of your group and recommends an ideal travel
                  destination in India.{' '}
                </p>
              </div>
              <div className="px-7 text-center">
                <h4 className="text-2xl">Links</h4>
                <p className=" text-center pt-4">
                  <Link href="/about-us">
                    <span className="leading-4">About Us</span>
                  </Link>
                  <br />
                  <Link className="leading-4" href="/sitemap.xml">
                    <span className="leading-4">Sitemap</span>
                  </Link>
                  <br />
                  <span className="leading-4">Privacy Policy</span>
                  <br />
                  <span className="leading-4">Terms of Use</span>
                </p>
              </div>
              <div className="px-7">
                <div className="inline-block">
                  <h4 className="text-2xl md:float-left mr-2 md:text-left text-center my-2">Contact Us </h4>
                  <a
                    className="float-left m-2"
                    href="https://www.facebook.com/Kahan-Chale-100411968960880/"
                    target="_blank"
                  >
                    <Image src="/images/facebook.png" className="w-8 h-8" alt="facebook" width="32" height="32" />
                  </a>
                  <a className="float-left m-2" href="https://twitter.com/kahan_chale/" target="_blank">
                    <Image src="/images/twitter.png" className="w-8 h-8" alt="twitter" width="32" height="32" />
                  </a>
                  <a className="float-left m-2" href="https://www.instagram.com/kahan_chale/" target="_blank">
                    <Image src="/images/instagram.png" className="w-8 h-8" alt="instagram" width="32" height="32" />
                  </a>
                  <a
                    className="float-left rounded m-2 bg-white"
                    href="https://www.linkedin.com/company/kahan-chale/"
                    target="_blank"
                  >
                    <Image src="/images/linkedin.png" className="w-8 h-8" alt="linkedin" width="32" height="32" />
                  </a>
                </div>

                <form className="pt-4" onSubmit={submitHandler}>
                  <input
                    type="text"
                    className="my-1 p-2 rounded w-full text-black"
                    placeholder="Name"
                    required={true}
                  />
                  <input
                    type="email"
                    className="my-1 p-2 rounded w-full text-black"
                    placeholder="Email"
                    required={true}
                  />
                  <textarea
                    className="my-1 p-2 h-12 resize-none rounded w-full text-black"
                    placeholder="Message"
                    required={true}
                  ></textarea>
                  <button type="submit" className="w-1/2 rounded-xl bg-themeOrange p-1">
                    Submit
                  </button>
                </form>
                {submit && <p className="py-4 text-themeOrange text-lg">Thanks !!</p>}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row text-white my-4 items-center justify-center">
          © 2021 Kahanchale.com All rights reserved.
        </div>
      </footer>
    </>
  )
}

export default Footer
