import Hero from '../components/hero'
import Navbar from '../components/navbar'
import Testimonials from '../components/testimonials'
import SubscribeUs from '../components/subscribeUs'
import Footer from '../components/footer'
import LocationCount from '../components/locationCount'
import { useEffect } from 'react'
import domain from '../constants/domain'

// import HowItWorks from 'components/howItWorks'
// import Benifits from 'components/benifits'
import Head from 'next/head'

export default function Home({ locations }) {
  useEffect(() => {
    sessionStorage.clear()
  }, [])
  return (
    <>
      <Head>
        <title>Experience Your Ideal Trip with KahanChale's Destination Finder Tool | Kahan Chale</title>
        <link rel="canonical" href="https://kahanchale.com/" />
        <meta
          property="og:title"
          content="Experience Your Ideal Trip with KahanChale's Destination Finder Tool | Kahan Chale"
        />
        <meta
          name="title"
          content="Experience Your Ideal Trip with KahanChale's Destination Finder Tool | Kahan Chale"
        />
        <meta property="twitter:author" content="Kahan Chale" />
        <meta
          property="og:description"
          content="Discover Your Ideal Indian Getaway with KahanChale's Destination Finder. Personalized Recommendations Based on Your Preferences, from Thrilling Adventure to Relaxing Retreats. Explore Hidden Gems and Must-See Attractions with Confidence, Knowing Our Expert Team Has Curated Every Destination. Collaborate with Friends to Plan Your Dream Trip and Make Memories that Will Last a Lifetime. Start Planning Your Next Adventure Today!"
        />
        <meta
          property="description"
          name="description"
          content="Discover Your Ideal Indian Getaway with KahanChale's Destination Finder. Personalized Recommendations Based on Your Preferences, from Thrilling Adventure to Relaxing Retreats. Explore Hidden Gems and Must-See Attractions with Confidence, Knowing Our Expert Team Has Curated Every Destination. Collaborate with Friends to Plan Your Dream Trip and Make Memories that Will Last a Lifetime. Start Planning Your Next Adventure Today!"
        />
        <meta
          property="twitter:description"
          content="Discover Your Ideal Indian Getaway with KahanChale's Destination Finder. Personalized Recommendations Based on Your Preferences, from Thrilling Adventure to Relaxing Retreats. Explore Hidden Gems and Must-See Attractions with Confidence, Knowing Our Expert Team Has Curated Every Destination. Collaborate with Friends to Plan Your Dream Trip and Make Memories that Will Last a Lifetime. Start Planning Your Next Adventure Today!"
        />
        <meta property="twitter:image:src" content="https://kahanchale.com/images/og-image1.jpeg" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://kahanchale.com/images/og-image1.jpeg" />
        <meta property="og:url" content="https://kahanchale.com/" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:image" content="https://kahanchale.com/images/og-image1.jpeg" />
      </Head>
      <Navbar isTransparentNav={true} />
      <Hero />
      <div className="bg-fixed bg-cover bg-subscribe bg-center bg-no-repeat min-h-screen">
        <LocationCount locations={locations} />
        {/* <HowItWorks />
        <Benifits /> */}
        <Testimonials />
        <SubscribeUs />
      </div>

      <Footer />
    </>
  )
}
export async function getStaticProps() {
  let locations = await fetch(domain + 'location-count')
  locations = await locations.json()
  return {
    props: {
      locations,
    },
  }
}
