const testimonialsData = [
  {
    text: 'KahanChale really saved me time and effort to find out an offbeat destination in Rajasthan where I can find places of my interest without going through several travel blogs',
    image: '/images/shubh.jpeg',
    name: 'Shubh',
  },
  {
    text: "Perfectly destinations according to my choices. I didn't even know about 8/10 places that were recommended. KahanChale is amazing to find new places in India. Cheers!",
    image: '/images/Shivam.png',
    name: 'Shivam',
  },
  {
    image: '/images/Sakshi.png',
    name: 'Sakshi',
    text: "Finally convincing my friends for a trip was so easy with KahanChale. I would definitely recommend to try it once before trip planning, you won't regret it.",
  },
]

export default testimonialsData
