import axios from 'axios'
import { useState } from 'react'
import domain from '../constants/domain'
import AirplaneHr from './airplaneHr'

const buttonStyle = {
  right: '5px',
  top: '50%',
  transform: 'translateY(-50%)',
  lineHeight: '42px',
  padding: '0 20px',
  outline: 'none',
  border: '0 none',
  borderRadius: '20px',
  transition: '0.4s',
}

const SubscribeUs = () => {
  const [submit, setSubmit] = useState(false)
  const submitHandler = (e) => {
    e.preventDefault()
    const email = e.target[0].value
    axios
      .post(domain + 'subscribe-us', { email })
      .then(() => {
        e.target[0].value = ''
        setSubmit(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <>
      <div className="py-32 min-h-screen">
        <div className="container px-4 mx-auto w-full">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full relative px-4 max-w-full">
              <div className="text-center">
                <div className="title">
                  <h2 className="text-black font-semibold text-3xl mb-6 md:text-5xl">
                    Subscribe for more information
                  </h2>
                  <AirplaneHr />
                  <p className="text-gray-500 text-sm leading-6 w-full mb-14 lg:px-8 mt-12">
                    Provide your email to get fortnightly newsletter about 1 new
                    offbeat destination in India
                  </p>
                </div>

                <form className="m-0" onSubmit={submitHandler}>
                  <div
                    className="relative items-center mx-auto"
                    style={{ maxWidth: '33.125rem' }}
                  >
                    <input
                      type="email"
                      name="email"
                      className="border-themeOrange border border-solid h-12 leading-10 rounded-3xl w-full p-4 focus:outline-none"
                      placeholder="Enter Your Email* "
                    />
                    <button
                      className="absolute h-10 bg-themeOrange text-gray-900 font-semibold text-sm uppercase"
                      type="submit"
                      style={buttonStyle}
                    >
                      Subscribe
                    </button>
                  </div>
                </form>
                {submit && (
                  <p className="py-4 text-themeOrange text-lg">
                    Thanks For Subscribing Us!!!
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

module.exports = SubscribeUs
