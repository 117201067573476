import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import '../node_modules/react-responsive-carousel/lib/styles/carousel.min.css'
import Reviews from './reviews'
import testimonialsData from '../constants/testimonialsData'
import AirplaneHr from './airplaneHr'

const Testimonials = () => {
  return (
    <>
      <div className=" overflow-hidden items-center pb-10 pt-12 drop-shadow-2xl min-h-screen">
        <div className="flex flex-row items-center px-4 justify-center">
          <h2 className="md:text-5xl text-3xl text-black font-semibold md:mb-4 mb-2">
            Hear it from our users
          </h2>
        </div>
        <AirplaneHr />
        <Carousel
          showArrows={true}
          noImages={true}
          showIndicators={true}
          infiniteLoop={true}
          interval={5000}
          showThumbs={false}
          autoPlay={true}
          swipeable={true}
          showStatus={false}
          transitionTime={2000}
        >
          {testimonialsData &&
            testimonialsData.map((testimonial, index) => {
              return (
                <div key={index}>
                  <Reviews
                    text={testimonial.text}
                    image={testimonial.image}
                    name={testimonial.name}
                  />
                </div>
              )
            })}
        </Carousel>
      </div>
    </>
  )
}

export default Testimonials
