import Link from 'next/link'
import { ArrowRightIcon } from '@heroicons/react/solid'
const Hero = () => {
  return (
    <>
      <div className="pt-20 hero-bg h-screen bg-no-repeat bg-center bg-cover lg:px-28 px-4">
        <div className="container mx-auto flex justify-center flex-col items-start h-full">
          <div
            className="text-xl text-white uppercase"
            style={{ letterSpacing: '4px' }}
          >
            OUR MISSION
          </div>
          <div
            className="lg:text-8xl lg:max-w-3xl text-5xl block text-white font-semibold"
            style={{ letterSpacing: '-2.4px', lineHeight: '1.21' }}
          >
            <h1>Your Perfect Destination</h1>
          </div>
          <div className="sm:text-3xl text-xl sm:my-3 my-5 text-white">
            <h2>
              Find the ideal Travel Destination in India for you and your group
            </h2>
          </div>
          <div className="flex flex-col w-full items-center justify-center my-6">
            <Link href="/quiz">
              <span className="py-3 inline-block px-6 text-xl bg-themeOrange rounded-md text-white font-semibold">
                <span className="float-left px-2">Lets find out </span>
                <span className="float-left py-1.5">
                  <ArrowRightIcon width="1rem" />
                </span>
              </span>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Hero
