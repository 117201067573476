import Image from 'next/image'

const AirplaneHr = () => {
  return (
    <div className="flex flex-row items-center mb-16 justify-center">
      <div className="inline-block">
        <hr className="border-themeOrange bg-themeOrange border md:mr-8 mr-4 md:mt-4 mt-2 md:h-1.5 h-1 md:w-20 w-10 float-left" />
        <Image
          src="/images/airplane.png"
          className="md:w-10 md:h-10 w-5 h-5 float-left"
          alt="airplane"
          width={100}
          height={100}
        />
        <hr className="border-themeOrange bg-themeOrange border md:ml-8 ml-4 md:mt-4 mt-2 md:h-1.5 h-1 md:w-20 w-10 float-left" />
      </div>
    </div>
  )
}
export default AirplaneHr
