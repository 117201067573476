const locationCountText = [
  {
    text: 'Destinations',
    image: '/images/placeholder.png',
    count: '300+',
  },
  {
    text: 'Beaches',
    image: '/images/sunset.png',
    count: '200+',
  },
  {
    text: 'Hills',
    image: '/images/mountains.png',
    count: '600+',
  },
  {
    text: 'Forests',
    image: '/images/forest.png',
    count: '500+',
  },
  {
    text: 'Heritage Places',
    image: '/images/temple.png',
    count: '900+',
  },
]

export default locationCountText
