import locationCountText from '../constants/locationCountText'

const LocationCount = ({ locations }) => {
  return (
    <>
      <div className="relative flex flex-row">
        <div className="container m-auto w-full px-4 py-16">
          <div className="grid md:grid-cols-5 grid-cols-1 gap-4 w-full">
            {locationCountText &&
              locationCountText.map((location, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-col items-center justify-center w-full"
                  >
                    <div className="p-auto text-center">
                      <img
                        className="h-20 w-20 m-auto"
                        src={location.image}
                        alt={location.text + ', kahanchale, Kahanchale'}
                      />
                      <p className="py-3 text-2xl">{location.text}</p>
                      <p className="text-2xl font-bold">
                        {location.text === 'Destinations'
                          ? parseInt(locations[location.text]) * 2
                          : locations[location.text]}
                        +
                      </p>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}

export default LocationCount
