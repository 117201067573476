import Image from 'next/image'

const Reviews = ({ text, image, name }) => {
  return (
    <>
      <figure className="relative overflow-hidden text-justify shadow-none reviews mb-12">
        <blockquote className="block rounded-lg relative bg-themeDarkBlue text-white font-medium testimonialBlockQuote">
          {text}
        </blockquote>
        <div className="m-0 uppercase text-center text-black">
          <Image
            className="max-w-full h-24 w-24 mb-4 inline-block relative rounded-full z-20"
            src={image}
            alt={'review, Kahanchale, ' + { name }}
            width={100}
            height={100}
          />
          <h5 className="m-0 font-extrabold opacity-80">{name}</h5>
        </div>
      </figure>
    </>
  )
}

export default Reviews
